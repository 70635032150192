import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import styles from "./recent-posts.module.scss"

const RecentPosts = () => {
  const data = useStaticQuery(graphql`
      {
          allMarkdownRemark(
              sort: {fields:frontmatter___date, order:DESC},
              filter: {
                  fields: {slug:{regex:"^\/resources\/"}}
              },
              limit: 5
          ) {
              nodes {
                  frontmatter {
                      rank
                      title
                      date
                  }
                  fields {
                      slug
                  }
              }
          }
      }`)

  const posts = data.allMarkdownRemark.nodes.map((post, index) =>
    <li key={`recent-post-${index}`}>
      <Link to={`/${post.fields.slug}`} activeClassName={styles.active}>
        {post.frontmatter.title}
      </Link>
    </li>
  )

  return (
    <div>
      <h3>Recent Posts</h3>
      <ul>
        {posts}
      </ul>
    </div>
  )
}


export default RecentPosts