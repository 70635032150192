import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import styles from "./top-posts.module.scss"

const TopPosts = () => {
  const data = useStaticQuery(graphql`
      {
          allMarkdownRemark(
              filter: {
                  frontmatter: {rank:{gt: 0}},
                  fields: {slug:{regex:"^\/resources\/"}},
              },
              sort: {fields:frontmatter___rank}
          ) {
              nodes {
                  frontmatter {
                      rank
                      title
                  }
                  fields {
                      slug
                  }
              }
          }
      }`)

  const posts = data.allMarkdownRemark.nodes.map((post, index) =>
    <li key={`top-posts-${index}`}>
      <Link to={`/${post.fields.slug}`} activeClassName={styles.active}>
        {post.frontmatter.title}
      </Link>
    </li>
  )

  return (
    <div>
      <h3>Top Posts</h3>
      <ul>
        {posts}
      </ul>
    </div>
  )
}


export default TopPosts