import React from "react"
import styles from "./sidenav.module.scss"

const Sidenav = (props) => {
  return (
    <div className={styles.nav}>
      {props.children}
    </div>
  )
}

export default Sidenav