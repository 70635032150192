import React from 'react'
import { graphql } from 'gatsby'
import Base from '../layouts/base'
import Hero from '../components/hero'
import Content from '../layouts/content'
import Sidenav from '../components/sidenav'
import contentStyles from '../layouts/content/content.module.scss'
import styles from './post.module.scss'
import TopPosts from '../components/sidenav/top-posts'
import RecentPosts from '../components/sidenav/recent-posts'

export const query = graphql`
    query($slug: String) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            frontmatter {
                title
                subtitle
                imageURL
                date
                author
            }
            html
        }
    }
`

const post = (props) => {
  return (
    <Base subject={props.data.markdownRemark.frontmatter.title}>
      <Hero
        heroImage={props.data.markdownRemark.frontmatter.imageURL}
        tagline={props.data.markdownRemark.frontmatter.title}
      />

      <Content sidenav={(
        <Sidenav>
          <div className={contentStyles.navGroup}><TopPosts/></div>
          <div className={contentStyles.navGroup}><RecentPosts/></div>
        </Sidenav>
      )}>
        <div className={styles.postMeta}>
          {props.data.markdownRemark.frontmatter.date}
          {props.data.markdownRemark.frontmatter.author && (' - ' + props.data.markdownRemark.frontmatter.author)}
        </div>
        <div className={styles.postContent}>
          <div dangerouslySetInnerHTML={{ __html: props.data.markdownRemark.html }}/>
        </div>
      </Content>

    </Base>
  )
}

export default post