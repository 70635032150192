import React from "react"
import styles from "./content.module.scss"

const Content = (props) => {
  return (
    <div className={styles.content}>
      <div className={styles.children}>
        {props.children}
      </div>

      <div className={styles.nav}>
        {props.sidenav}
      </div>
    </div>
  )
}

export default Content